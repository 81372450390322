import Service from "@/services/base.service";

export default {
  methods: {
    async sendData(serviceName, methodName, params) {
      try {
        const BaseService = new Service(serviceName);
        const { data } = await BaseService[methodName](params);
        console.log(data)
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    async sendAttachment(serviceName, methodName, params) {
      try {
        const formData = new FormData();
        formData.append('file', params);

        const BaseService = new Service(serviceName);
        const { data } = await BaseService[methodName](formData);
        console.log(data)
        return data;
      } catch (error) {
        console.log(error);
      }
    },
    getNameValue(data) {
      const values = {};
      for (let key in data) {
        const section = data[key];
        for (let subKey in section) {
          const fields = section[subKey];
          for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            values[field.name] = field.value;
          }
        }
      }
      return values;
    },
  }
}