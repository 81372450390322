<template>
  <div class="form-group">
    <div class="overflow-hidden" :style="`width:${size};height:${size}`">
      <img v-if="!disabled || !readonly" class="img-fluid mb-3" :src="selectedImage" alt="">
      <img v-if="disabled || readonly" class="img-fluid mb-3" :src="fileValue" alt="">
    </div>
    <label v-if="!disabled || !readonly" :for="inputId" class="form-label my-3">{{ label }}</label>
    <input v-if="!disabled || !readonly" :id="inputId" class="form-control" type="file" @change="handleFileUpload"/>
  </div>
</template>

<script>
import BaseProps from '@/scripts/forms/base-props';

export default {
  mixins: [BaseProps],
  data() {
    return {
      selectedImage: '/assets/media/avatars/blank.png',
      localValue: this.fileValue,
    };
  },
  computed: {
    inputId() {
      return `input-${Math.random().toString(36).substr(2, 9)}`;
    },
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.selectedImage = URL.createObjectURL(file);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('FormInput', {index:this.index, parentIndex:this.parentIndex, indexMain:this.indexMain, value:file});
      }, 500); 
    },
  }
};
</script>