<template>
  <div class="form-group">
    <label :for="inputId" class="form-label">{{ label }}</label>
    <div class="input-group">
      <input :id="inputId" class="form-control" type="text" v-model="localValue" v-bind="inputProps" />
      <span v-if="tail !== ''" class="input-group-text" id="basic-addon2">{{ tail }}</span>
    </div>
  </div>
</template>

<script>
import BaseProps from '@/scripts/forms/base-props';

export default {
  mixins: [BaseProps],
  props: {
    tail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      localValue: this.value, // initialize localValue with the value prop
    };
  },
  computed: {
    inputId() {
      return `input-${Math.random().toString(36).substr(2, 9)}`;
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal; // update localValue when the value prop changes
    },
    localValue(newVal) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('FormInput', {index:this.index, parentIndex:this.parentIndex, indexMain:this.indexMain, value:newVal});
      }, 200); 
    },
  },
};
</script>