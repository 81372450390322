export default {
  data() {
    return {
      template: {
        biodata: [
          {
            '3': [
              {label: 'Ubah Foto', value: '/assets/media/avatars/pas-foto-asn.jpeg', type: 'file', name: 'foto', readonly: true}, 
            ],
            '9': [
              {label: 'Nama Lengkap', value: 'BAMBANG YUNIANTO, S.Sos.', type: 'text', name: 'nama_lengkap', readonly: true},
              {label: 'Pangkat/Gol', value: 'Penata Muda Tk. I III/b', type: 'text', name: 'pangkat_korps', readonly: true},
              {label: 'NPR/NIP', value: '197906012001121004', type: 'text', name: 'pangkat_korps', readonly: true},
              {label: 'Jabatan', value: 'Kaurtu Taud Spers TNI', type: 'text', name: 'pangkat_korps', readonly: true},
              {label: 'Kesatuan', value: 'Mabes TNI', type: 'text', name: 'kesatuan', readonly: true},
            ]
          }
        ],
        sinyalemen:[
          {
            '12': [
              {label: 'Tinggi', value: '167', type: 'text', tail: 'cm', name: 'tinggi', readonly: true},
              {label: 'Berat', value: '70', type: 'text', tail: 'kg', name: 'berat', readonly: true},
            ],
          },
          {
            '12': [
              {label: 'Rambut', value: 'Lurus', type: 'text', name: 'rambut', readonly: true},
              {label: 'Mata', value: 'Hitam Bulat', type: 'text', name: 'mata', readonly: true},
              {label: 'Golongan Darah', value: 'AB', type: 'text', name: 'golongan_darah', readonly: true},
            ],
          },
          {
            '12': [
              {label: 'Tempat Lahir', value: 'Banyumas', type: 'text', name: 'tempat_lahir', readonly: true},
              {label: 'Tanggal Lahir', value: '01-06-1979', type: 'text', name: 'tanggal_lahir', readonly: true},
            ],
          },
          {
            '12': [
              {label: 'Agama', value: 'Islam', type: 'text', name: 'agama', readonly: true},
            ],
          },
          {
            '12': [
              {label: 'Kontak Darurat', value: '+6289655512XXX', type: 'text', name: 'kontak_darurat', readonly: true},
            ],
          },
          {
            '12': [
              {label: 'Alamat Rumah', value: 'Kp. Raden Rt. 04/02 Jl. RS. Jatisampurna Gg. Pelangi No. 79 Jatiraden, Jatisampurna', type: 'textarea', name: 'alamat_rumah', readonly: true},
            ],
          },
        ],
        kehormatan:[
          {
            value: '',
            placeholder: 'Tanda Kehormatan',
          },
        ],
      }
    };
  },
}