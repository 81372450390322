<template>
  <div class="form-group">
    <div class="fs-3 fw-bold mb-3">
      {{ title }}
      <button v-if="!disabled" type="button" class="btn btn-sm btn-icon btn-success float-end" @click="add">+</button>
    </div>
    <div v-for="(form, index) in forms" :key="index" class="row">
      <TextDynamic 
        :value="form.value" :nomor="parseInt(index) + 1" 
        :placeholder="form.placeholder"
        @TextDynamic="handleTextDynamic"
        :index="parseInt(index)"
        :disabled="form.disabled"
        :remove="remove">
      </TextDynamic>
    </div>
  </div>
</template>

<script>
import TextDynamic from "@/components/forms/TextDynamic.vue";

export default {
  components: {
    TextDynamic,
  },
  props: {
    forms: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    add: {
      type: Function,
      default: function() {
        return ''
      },
    },
    remove: {
      type: Function,
      default: function() {
        return ''
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
    };
  },
  methods: {
    getColumn(value) {
      if (value === '12') {
        return 'd-flex gap-3';
      }
      return 'col-'+value;
    },
    handleTextDynamic(value) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        let updatedForms = [...this.forms]; 
        updatedForms[value.index].value = value.value; 
        this.$emit('FormDynamic', updatedForms);
      }, 500); 
    }
  },
};
</script>