function createProp(type, defaultValue, required = false) {
  return {
    type: type,
    default: defaultValue,
    required: required,
  };
}

export default {
  props: {
    label: createProp(String, '', true),
    value: createProp(String, ''),
    fileValue: createProp([Object, File], {}),
    index: createProp(Number, 0),
    parentIndex: createProp(Number, 0),
    indexMain: createProp(Number, 0),
    size: createProp(String, '245px'),
    disabled: createProp(Boolean, false),
    readonly: createProp(Boolean, false),
    required: createProp(Boolean, false),
    placeholder: createProp(String, '')
  },
  computed: {
    inputProps() {
      return {
        placeholder: this.placeholder,
        disabled: this.disabled,
        readonly: this.readonly
      };
    }
  }
}