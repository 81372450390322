<template>
  <div class="form-group">
    <label :for="inputId" class="form-label">&nbsp;</label>
    <div class="input-group">
      <span class="input-group-text" id="basic-addon2">{{ nomor }}.</span>
      <input :id="inputId" class="form-control" type="text" v-model="localValue" v-bind="inputProps" />
      <button v-if="!disabled" type="button" class="btn btn-danger" @click="remove(index)">Hapus</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0,
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    nomor: {
      type: Number,
      default: 1,
    },
    remove: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
      localValue: this.value, // initialize localValue with the value prop
    };
  },
  computed: {
    inputId() {
      return `input-${Math.random().toString(36).substr(2, 9)}`;
    },
    inputProps() {
      return {
        placeholder: this.placeholder,
        disabled: this.disabled,
        readonly: this.readonly
      };
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal; // update localValue when the value prop changes
    },
    localValue(newVal) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('TextDynamic', {index:this.index, value:newVal});
      }, 200); 
    },
  },
};
</script>