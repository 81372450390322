<template>
  <div class="form-group">
    <div class="fs-3 fw-bold mb-3">{{ title }}</div>
    <div v-for="(form, index) in forms" :key="index" class="row">
      <div v-for="(isi, key) in form" :key="key" :class="getColumn(key)">
        <div v-for="(item, nomor) in isi" :key="nomor" :class="key == 12 ?'flex-row-fluid mb-3':'mb-3'">
          <component
            :is="componentsMap[item.type]"
            v-if="item.type"
            v-bind="itemProps(index, key, nomor, item)"
            :listData="item.type === 'select' ? item.data : undefined"
            @FormInput="handleInput"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileInput from "@/components/forms/FileInput.vue";
import TextInput from "@/components/forms/TextInput.vue";
import TextArea from "@/components/forms/TextArea.vue";
import DateInput from "@/components/forms/DateInput.vue";
import SelectOption from "@/components/forms/SelectOption.vue";

export default {
  components: {
    TextInput,
    TextArea,
    FileInput,
    DateInput,
    SelectOption,
  },
  props: {
    forms: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    getColumn(value) {
      if (value === '12') {
        return 'd-flex gap-3';
      }
      return 'col-'+value;
    },
    handleInput(value) {
      let updatedForms = [...this.forms]; 
      updatedForms[value.indexMain][value.parentIndex][value.index].value = value.value; 
      this.$emit('FormGroup', updatedForms);
    },
    itemProps(index,key,nomor,item) {
      return {
        label: item.label,
        indexMain: parseInt(index),
        parentIndex: parseInt(key),
        index: parseInt(nomor),
        value: item.value,
        tail: item.tail,
        placeholder: item.placeholder,
        disabled: item.disabled,
        readonly: item.readonly,
      };
    },
  },
  computed: {
    componentsMap() {
      return {
        text: 'TextInput',
        date: 'DateInput',
        select: 'SelectOption',
        textarea: 'TextArea',
        file: 'FileInput'
      };
    },
    // other computed properties...
  },
};
</script>