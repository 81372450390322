<template>
  <div class="form-group">
    <label :for="inputId" class="form-label">{{ label }}</label>
    <textarea :id="inputId" v-bind="inputProps" class="form-control" v-model="localValue">
    </textarea>
  </div>
</template>

<script>
import BaseProps from '@/scripts/forms/base-props';

export default {
  mixins: [BaseProps],
  data() {
    return {
      localValue: this.value, // initialize localValue with the value prop
    };
  },
  computed: {
    inputId() {
      return `input-${Math.random().toString(36).substr(2, 9)}`;
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal; // update localValue when the value prop changes
    },
    localValue(newVal) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('FormInput', {index:this.index, parentIndex:this.parentIndex, indexMain:this.indexMain, value:newVal});
      }, 200); 
    },
  },
};
</script>