<template>
  <div class="form-group">
    <label :for="inputId" class="form-label">{{ label }}</label>
    <div class="input-group">
      <select :id="inputId" class="form-select" v-model="localValue" >
        <option value="" disabled selected>Silahkan Pilih</option>
        <option v-for="(item, index) in listData" :key="index" :value="item.value">{{ item.label }}</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    listData: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    parentIndex: {
      type: Number,
      default: 0,
    },
    indexMain: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localValue: this.value, // initialize localValue with the value prop
    };
  },
  computed: {
    inputId() {
      return `input-${Math.random().toString(36).substr(2, 9)}`;
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal; // update localValue when the value prop changes
    },
    localValue(newVal) {
      console.log('newVal', newVal);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$emit('FormInput', {index:this.index, parentIndex:this.parentIndex, indexMain:this.indexMain, value:newVal});
      }, 200); 
    },
  },
};
</script>